import MyButton from "./MyButton";
import {CSVLink} from "react-csv";

/**
 * Controls settings panel
 * Also handles CSV exports of currently filtered data (excluding limit settings)
 * stateless
 * @param props
 * @returns {JSX.Element}
 */
export default function MySettings(props) {

    const {handleLimit, actuallimit, domainstotal, sorting, handleSorting, filteredDomains, filteredHistory, mode, toggleEditMode, allowEdit, handleMobileDropdown, massActionSelection, showMassActionModal, toggleAllowMassAction, allowMassAction} = props;
    const today = new Date().toISOString().slice(0,10);

    // domains table export headers
    const domainExportHeaders = [
        { label: "Doména", key: "name" },
        { label: "Klient", key: "owner" },
        { label: "Datum Expirace", key: "expiryDate" },
    ];

    // domains table export data
    const remappedFilteredDomains = filteredDomains.map(domain => {
        return {
            ...domain,
            expiryDate: new Date(domain.expiryDate).toLocaleDateString()
        }
    })

    // domains table export
    const domainCsvReport = {
        data: remappedFilteredDomains,
        headers: domainExportHeaders,
        filename: `domains_export_${today}.csv`
    };

    // transaction history table export headers
    const historyExportHeaders = [
        { label: "Doména", key: "name" },
        { label: "Klient", key: "owner" },
        { label: "Původní expirace", key: "previousExpiry" },
        { label: "Prodlouženo o (měsíce)", key: "renewYears" },
        { label: "Datum transakce", key: "renewDate" },
        { label: "Cena transakce", key: "renewPrice" },
        { label: "Prodloužil", key: "user" },
        { label: "Fakturováno", key: "isBilled" },
        { label: "Fakturaci potvrdil", key: "billConfirmedBy" },
        { label: "Fakturace potvrzena dne", key: "billConfirmedAt" },
    ];


    // transaction history table export data
    const remappedFilteredHistory = filteredHistory.map(domain => {
        return {
            ...domain,
            previousExpiry: new Date(domain.previousExpiry).toLocaleDateString(),
            renewYears: Number.parseInt(domain.renewYears) * 12,
            renewDate: new Date(domain.renewDate).toLocaleDateString(),
            isBilled: domain.isBilled ? 'Ano' : 'Ne',
            billConfirmedBy: domain.billConfirmedBy ? domain.billConfirmedBy : '',
            billConfirmedAt: domain.billConfirmedAt ? new Date(domain.billConfirmedAt).toLocaleDateString() : ''
        }
    })

    // transaction history table export
    const historyCsvReport = {
        data: remappedFilteredHistory,
        headers: historyExportHeaders,
        filename: `history_export_${today}.csv`
    };

    return (
        <>
            {/* ALLOW EDIT SWITCH -  show only for domains */}
            {mode !== 'history' &&
             <div className={'side__box file-ico'}>
                 <header tabIndex={0} onClick={(event => handleMobileDropdown(event))}>Editace</header>
                 <section>
                     <div className={'side__switch'}>
                         <input id={'switch-1'} type={"checkbox"} onChange={toggleEditMode} checked={allowEdit}/>
                         <label htmlFor={'switch-1'}>Editovat</label>
                     </div>
                 </section>
            </div>}

            {/* MASS ACTION -  show only for domains */}
            {mode !== 'history' &&
             <div className={'side__box file-ico'}>
                 <header tabIndex={0} onClick={(event => handleMobileDropdown(event))}>Hromadné akce</header>
                 <section>
                     <div className={'side__switch'}>
                         <input id={'switch-2'} type={"checkbox"} onChange={toggleAllowMassAction} checked={allowMassAction}/>
                         <label htmlFor={'switch-2'}>Hromadné akce</label>
                     </div>
                     {massActionSelection.length > 0 && allowMassAction && <p><br /><MyButton
                         className={'button red w-100'}
                         onClick={showMassActionModal}
                     >Prodloužit o rok
                     </MyButton></p>}
                 </section>
            </div>}

            {/*{mode !== 'history' &&*/}
            {/*    <div className={'side__box file-ico'}>*/}
            {/*        <header tabIndex={0} onClick={(event => handleMobileDropdown(event))}>Hromadná akce</header>*/}
            {/*        <section>*/}
            {/*            <p>{massActionSelection.length > 0 && <MyButton*/}
            {/*                className={'button red w-100'}*/}
            {/*                onClick={showMassActionModal}*/}
            {/*            >Prodloužit o rok*/}
            {/*            </MyButton>}</p>*/}
            {/*        </section>*/}
            {/*    </div>}*/}

            {/* LIMIT BUTTONS */}
            <div className={'side__box sort-ico'}>
                <header tabIndex={0} onClick={(event => handleMobileDropdown(event))}>Položek na stránku</header>
                <section>
                    <ul className={'side__limiter'}>
                        <li><MyButton className='button' onClick={() => handleLimit(20)} actuallimit={actuallimit} domainstotal={domainstotal}>20</MyButton></li>
                        <li><MyButton className='button' onClick={() => handleLimit(50)} actuallimit={actuallimit} domainstotal={domainstotal}>50</MyButton></li>
                        <li><MyButton className='button' onClick={() => handleLimit(100)} actuallimit={actuallimit} domainstotal={domainstotal}>100</MyButton></li>
                        <li><MyButton className='button' onClick={() => handleLimit('all')} actuallimit={actuallimit} domainstotal={domainstotal}>Vše</MyButton></li>
                    </ul>
                </section>
            </div>

            {/* SORTING BUTTONS */}
            <div className={'side__box cog-ico'}>
                <header tabIndex={0} onClick={(event => handleMobileDropdown(event))}>Řazení dle</header>
                <section>
                    <p><MyButton
                        className={sorting.column === 'name' ? 'button green w-100' : 'button border-white w-100'}
                        onClick={() => handleSorting('name')}
                    >Dle Domény
                    </MyButton></p>
                    <p><MyButton
                        className={sorting.column === 'owner' ? 'button green w-100' : 'button border-white w-100'}
                        onClick={() => handleSorting('owner')}
                    >Dle Klienta

                    </MyButton></p>
                    {mode !== 'history' && <p><MyButton
                        className={sorting.column === 'expiryDate' ? 'button green w-100' : 'button border-white w-100'}
                        onClick={() => handleSorting('expiryDate')}
                    >Dle Expirace
                    </MyButton></p>}
                    {mode === 'history' && <p><MyButton
                        className={sorting.column === 'renewDate' ? 'button green w-100' : 'button border-white w-100'}
                        onClick={() => handleSorting('renewDate')}
                    >Dle Data Transakce
                    </MyButton></p>}
                    {mode === 'history' && <p><MyButton
                        className={sorting.column === 'billConfirmedAt' ? 'button green w-100' : 'button border-white w-100'}
                        onClick={() => handleSorting('billConfirmedAt')}
                    >Dle Data Fakturace
                    </MyButton></p>}
                </section>
            </div>

            {/* EXPORT BUTTON */}
            <div className={'side__box exp-ico'}>
                <header tabIndex={0} onClick={(event => handleMobileDropdown(event))}>Export</header>
                <section>
                    {mode === 'domains' && <p><CSVLink className='button border-white w-100' style={{zIndex: 0}} {...domainCsvReport}>CSV</CSVLink></p>}
                    {mode === 'history' && <p><CSVLink className='button border-white w-100' style={{zIndex: 0}} {...historyCsvReport}>CSV</CSVLink></p>}
                    <p className={'text-white-50 text-center'}>Export aktuálního výběru</p>
                </section>
            </div>
        </>
    )
}