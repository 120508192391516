import clsx from "clsx";
import {useContext} from "react";
import {MyContext} from "../context/MyContext";

/**
 * Universal button component
 * stateless
 * @param props
 * @returns {JSX.Element}
 */
export default function Button(props) {
    const {children, className, ...rest } = props;
    const {isLoading} = useContext(MyContext);
    const modalStyle = props?.modal ? {} : {zIndex: 0};

    // fix problems with modals coming from 3rd party css


    // handles different classes for control buttons to show which one is active
    let itemLimitClass = '';
    if (props.actuallimit) {
        if (props.actuallimit === parseInt(children) ||
            (children === 'Vše' && (props.domainstotal === props.actuallimit))) {
            itemLimitClass = 'active';
        } else {
            itemLimitClass = '';
        }
    }
    // add classNames
    const classNames = clsx({
            btn: true,
        },
        className,
        itemLimitClass
    );

    return (
        <button className={classNames} {...rest} disabled={isLoading} style={modalStyle}>
            {children}
        </button>
    );
}