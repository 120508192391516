import {useEffect, useState} from "react";
import MyButton from "./MyButton";
import Input from "./Input";
import MsgBar from "./MsgBar";
import StatusBar from "./StatusBar";
import MySettings from "./MySettings";
import {useContext} from "react";
import {MyContext} from "../context/MyContext";
import swal from "sweetalert";
import config from "../config";
import RenewForm from "./RenewForm";
import MyModal from "./MyModal";
import sideLogo from "../img/logo-p.svg";
import ModalMassAction from "./ModalMassAction";

/**
 * This is the main place where the app is running.
 * We got all the data and hold them in component state. When we do something that changes DB, we accordingly change the data we hold
 * @param props
 * @returns {JSX.Element}
 */
export default function MyApp(props) {

    // STATES:
    // data we received from DB - wo only modify them when we change something in DB
    const [domains, setDomains] = useState(props.domains);
    // data we are currently showing based on various filters
    const [filteredDomains, setFilteredDomains] = useState(props.domains);
    // data we received from DB - wo only modify them when we change something in DB
    const [history, setHistory] = useState(props.history);
    // data we are currently showing based on various filters
    const [filteredHistory, setFilteredHistory] = useState(_sortByDate(history, 'renewDate'));
    // all the users data
    const [users, setUsers] = useState(props.users);
    // current user data
    const [user, setUser] = useState(props.user);
    // tele3 credit
    const [credit, setCredit] = useState(props.credit);

    // the table we show, currently we have 'domains' and 'history' - make those separate pages instead if the app grows further
    const [mode, setMode] = useState('domains');

    // message bar - show? what message?
    const [showMsgBar, setShowMsgBar] = useState(false);
    const [message, setMessage] = useState({msg: '', domain:''});

    // filters, limits, sorting preset
    const [filter, setFilter] = useState('');
    const [limit, setLimit] = useState(50);
    const [sorting, setSorting] = useState({column: 'expiryDate', direction: 'asc'});

    // allow table edit - currently just the "klient" field
    const [allowEdit, setAllowEdit] = useState(false);

    // allow mass action
    const [allowMassAction, setAllowMassAction] = useState(false);

    // mass actions selection
    const [massActionSelection, setMassActionSelection] = useState([]);

    // show mass action modal
    const [showMassAction, setShowMassAction] = useState(false);

    //  show modal dialog with setting
    const [showSettings, setShowSettings] = useState(false);
    // END OF STATES

    // context functions - when loading is on all the buttons are disabled
    const {loadingOn, loadingOff} = useContext(MyContext);

    // get variables from sessionStorage
    const accessToken = sessionStorage.getItem(config.sessionStorageKey);
    const testMode = sessionStorage.getItem('test') === 'true';


    // filter - when filtering we always want to start with original data
    useEffect(() => {
        if (mode === 'history') {
            const newFilteredHistory = history.filter(history => {
                return ((history.owner.toLowerCase().includes(filter.toLowerCase())
                    || history.name.toLowerCase().includes(filter.toLowerCase())))
            })
            setFilteredHistory(newFilteredHistory);
        }

        if (mode === 'domains') {
            const newFilteredDomains = domains.filter(domain => {
                return ((domain.owner.toLowerCase().includes(filter.toLowerCase())
                    || domain.name.toLowerCase().includes(filter.toLowerCase())))
            })
            setFilteredDomains(newFilteredDomains);
        }
    }, [filter]);

    /**
     * reload app to sync with server
     */
    function reloadApp () {
        window.location.reload();
    }

    /**
     * Send request to extend domain expiry date
     * @param item domain object
     * @param period in years
     */
    function handleRenewClick(item, period) {
        loadingOn();
        let renewYears = Number.parseInt(period);

        // in case someone fiddles with our code. Ve validate this on BE anyway but...
        if (renewYears <= 0 || isNaN(renewYears)) {
            renewYears = 1;
        }
        if (renewYears > 10) {
            renewYears = 10;
        }

        // calculate new expiry date to be updated in state
        const newExpiryDate = new Date(item.expiryDate);
        newExpiryDate.setFullYear(newExpiryDate.getFullYear() + renewYears);

        // build items
        const updatedDomain = {
            ...item,
            isBilled: false,
            expiryDate: newExpiryDate.toISOString().slice(0, 10),
            lastRenew: new Date().toISOString().slice(0, 10)
        }
        const historyItem = {
            name: item.name,
            owner: item?.owner,
            previousExpiry: item?.expiryDate,
            user: sessionStorage.getItem('userName')
        }

        // set fetch props
        const renewFetchProps = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({domain: item.name, renewYears:renewYears, user: sessionStorage.getItem('userName')})
        }

        // send request
        fetch('/api/tele3/renew', renewFetchProps)
            .then(response => response.json())
            .then(data => {
                if (data.errorCode) {
                    throw new Error(data.errorCode);
                }

                // we need to get the exact position of object in array so that we return it edited to the same spot
                const indexInDomains = domains.indexOf(item);
                const previousDomains = domains.slice(0, indexInDomains);
                const nextDomains = domains.slice(indexInDomains + 1)

                // same in filtered data
                const indexInFilteredDomains = filteredDomains.indexOf(item);
                const previousFilteredDomains = filteredDomains.slice(0, indexInFilteredDomains);
                const nextFilteredDomains = filteredDomains.slice(indexInFilteredDomains + 1)

                const now = new Date().toISOString();

                // update state
                setFilteredDomains([...previousFilteredDomains, updatedDomain, ...nextFilteredDomains]);
                setDomains([...previousDomains, updatedDomain, ...nextDomains]);
                setHistory([...history, {_id: data.historyItemId,...historyItem, renewDate: now, renewPrice: data.deltaCredit, renewYears: renewYears}]);
                setShowMsgBar(true);
                setMessage({...message, msg: `Platnost domény ### prodloužena (${renewYears}).`, domain: item.name});
                setCredit(data.credit);
            })
            .catch(err => {
                // display errors in modal dialog if any
                swal({
                    title: 'FATAL ERROR, je nám líto..',
                    text: `${err}`,
                    icon: 'error',
                    button: 'Co nadělám',
                    dangerMode: true
                });
            })
            .finally(() => {
                loadingOff();
            })
    }


    /**
     * Send request to extend domain expiry date in mass action
     * This function does not update state - we need to reload app to sync with server again after all these requests are finished
     * @param item domain object
     * @param period in years
     */
    async function handleRenewDomainInMassAction(item, period) {

        let renewYears = Number.parseInt(period);

        // in case someone fiddles with our code. Ve validate this on BE anyway but...
        if (renewYears <= 0 || isNaN(renewYears)) {
            renewYears = 1;
        }
        if (renewYears > 10) {
            renewYears = 10;
        }

        // set fetch props
        const renewFetchProps = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({domain: item.name, renewYears:renewYears, user: sessionStorage.getItem('userName')})
        }

        try {
            const response = await fetch('/api/tele3/renew', renewFetchProps);
            const data = await response.json();

            if (response.status === 200) {
                return false
            } else {
                return data.errorCode
            }
        } catch (e) {
            return e.toString();
        }
    }


    /**
     * Confirm the transaction billed in transaction history
     * @param item - transaction history object
     */
    function handleBillConfirmClick(item) {
        loadingOn()

        // build updated item
        const updatedHistoryItem = {
            ...item,
            isBilled: true,
            billConfirmedBy: sessionStorage.getItem('userName'),
            billConfirmedAt: new Date().toISOString()
        }

        // send request
        fetch('/api/history', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({data: updatedHistoryItem})
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw new Error("chyba komunikace se serverem");
                }
            })
            .then(data => {
                // we need to get the exact position of object in array so that we return it edited to the same spot
                const indexInHistory = history.indexOf(item);
                const previousHistory = history.slice(0, indexInHistory);
                const nextHistory = history.slice(indexInHistory + 1)

                // same in filtered data
                const indexInFilteredHistory = filteredHistory.indexOf(item);
                const previousFilteredHistory = filteredHistory.slice(0, indexInFilteredHistory);
                const nextFilteredHistory = filteredHistory.slice(indexInFilteredHistory + 1)

                // set state
                setFilteredHistory([...previousFilteredHistory, updatedHistoryItem, ...nextFilteredHistory]);
                setHistory([...previousHistory, updatedHistoryItem, ...nextHistory]);
                setShowMsgBar(true);
                setMessage({...message, msg: 'Potvrzena fakturace domény ###', domain: item.name});
            })
            // display errors in modal dialog if any
            .catch((err) => {
                swal({
                    title: 'FATAL ERROR, je nám líto..',
                    text: `${err}`,
                    icon: 'error',
                    button: 'Co nadělám',
                    dangerMode: true
                });
            })
            .finally(() => {
                loadingOff();
            })
    }

    /**
     * Basically replaces navigation
     * @param mode string
     */
    function handleChangeModeClick(mode) {
        switch (mode) {
            case 'domains':
                // reset filtered items to all
                setFilteredDomains(domains);
                setMode('domains')
                // reset msg bar
                setMessage({...message, msg: '', domain:''});
                setShowMsgBar(false);
                // reset filters
                setFilter('');
                if (sorting.column === 'renewDate') {
                    setSorting({...sorting, column: 'expiryDate'})
                }
                break;

            case 'history':
                // reset filtered items to all
                setFilteredHistory(history);
                setMode('history');
                // reset msg bar
                setMessage({...message, msg: '', domain:''});
                setShowMsgBar(false);
                // reset filters
                setFilter('');
                if (sorting.column === 'expiryDate') {
                    setSorting({...sorting, column: 'renewDate'})
                }
                break;
            default:
                break;
        }
        setMassActionSelection([]);
        setAllowMassAction(false);
    }

    /**
     * Handles sorting
     * @param column string column name
     */
    function handleSortClick(column) {

        let domainsCopy = [];
        let filteredDomainsCopy = [];

        if (mode === 'history') {
            domainsCopy = [...history];
            filteredDomainsCopy = [...filteredHistory];
        }
        if (mode === 'domains') {
            domainsCopy = [...domains];
            filteredDomainsCopy = [...filteredDomains];
        }

        // toggle directions
        let direction = 'asc'
        if (column === sorting.column) {
            if (sorting.direction === 'asc') {
                direction = 'desc';
            } else {
                direction = 'asc'
            }
        }

        // handle sorting by string
        if (column === 'name' || column === 'owner') {
            domainsCopy.sort((a, b) => {
                if (direction === 'asc') {
                    return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
                } else {
                    return b[column].toLowerCase().localeCompare(a[column].toLowerCase());
                }
            })
            filteredDomainsCopy.sort((a, b) => {
                if (direction === 'asc') {
                    return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
                } else {
                    return b[column].toLowerCase().localeCompare(a[column].toLowerCase());
                }
            })
        }

        // handle sorting by dates
        // we know that history has renewDate and billConfirmedAt while domains have expiryDate
        if (column === 'expiryDate' || 'billConfirmedAt' || 'renewDate' ) {
            domainsCopy.sort((a, b) => {
                let da = new Date(a[column]);
                let db = new Date(b[column]);
                if (direction === 'asc') {
                    return da - db;
                } else return db - da;
            });
            filteredDomainsCopy.sort((a, b) => {
                let da = new Date(a[column]);
                let db = new Date(b[column]);
                if (direction === 'asc') {
                    return da - db;
                } else return db - da;
            });
        }

        // set state
        if (mode === 'history') {
            setHistory(domainsCopy);
            setFilteredHistory(filteredDomainsCopy);
        }
        if (mode === 'domains') {
            setDomains(domainsCopy)
            setFilteredDomains(filteredDomainsCopy)
        }

        setSorting({column: column, direction: direction})
        setMassActionSelection([]);
        setAllowMassAction(false);
    }

    /**
     * Edit "klient" field in domains table
     * @param event form submit event
     * @param item domain object
     */
    function handleEditOwnerSubmit(event, item) {
        event.preventDefault();
        loadingOn();
        // send request
        fetch('/api', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({data: {...item, owner: event.target.owner.value, note: event.target.note.value}})
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw new Error("chyba komunikace se serverem");
                }
            })
            .then(data => {
                // we need to get the exact position of object in array so that we return it edited to the same spot
                const indexInDomains = domains.indexOf(item);
                const previousDomains = domains.slice(0, indexInDomains);
                const nextDomains = domains.slice(indexInDomains + 1)

                // same with filtered stuff
                const indexInFilteredDomains = filteredDomains.indexOf(item);
                const previousFilteredDomains = filteredDomains.slice(0, indexInFilteredDomains);
                const nextFilteredDomains = filteredDomains.slice(indexInFilteredDomains + 1)

                const updatedDomain = {...item, owner: event.target.owner.value, note: event.target.note.value}

                // this is the visual representation of successful change
                event.target.myEditButton.innerText = 'uloženo';
                setTimeout(() => {
                    event.target.myEditButton.innerText = 'uložit';
                },1200)

                // set states
                setFilteredDomains([...previousFilteredDomains, updatedDomain, ...nextFilteredDomains]);
                setDomains([...previousDomains, updatedDomain, ...nextDomains]);
            })
            .catch((err) => {
                swal({
                    title: 'FATAL ERROR, je nám líto.. Zkuste to prosím později',
                    text: `${err}`,
                    icon: 'error',
                    button: 'Co nadělám',
                    dangerMode: true
                });
            })
            .finally(() => {
                loadingOff();
            })
    }

    /**
     * set filter state on input change
     * filter state change triggers our useEffect filter
     * @param event input change
     */
    function handleFilter(event) {
        setFilter(event.target.value)
    }

    /**
     * set limit state - how many items to display
     * @param newLimit
     */
    function handleLimit(newLimit) {
        if (newLimit === 'all') {
            setLimit(domains.length)
        } else {
            setLimit(newLimit);
        }
    }

    /**
     * handles different styles of MsgBar based on mode
     * @returns {string} className
     */
    function getBarClassName() {
        if (!showMsgBar) {
            return 'App-msg-bar-blank';
        }
        if (mode === 'history') {
            return 'App-msg-bar-history';
        }
        return 'App-msg-bar';
    }

    /**
     * toggles edit mode
     */
    function toggleEditMode() {
        setAllowEdit((allowEdit) => !allowEdit);
    }

    /**
     * toggles mass action mode
     */
    function toggleAllowMassAction() {
        if (allowMassAction) {
            setMassActionSelection([]);
        }
        setAllowMassAction((allowMassAction) => !allowMassAction);
    }

    /**
     * open modal dialog with settings
     */
    function openSettings() {
        setShowSettings(true);
    }

    /**
     * close modal dialog with settings
     */
    function closeSettings() {
        setShowSettings(false);
    }

    /**
     * open modal dialog with massAction
     */
    function showMassActionModal() {
        setShowMassAction(true);
    }

    /**
     * for testing purposes - simulates waiting for data
     * @param ms milliseconds
     */
    function wait(ms) {
        loadingOn();
        setTimeout(() => {
            loadingOff();
        }, ms)
    }

    /**
     * handles menu dropdowns for mobile layout
     * @param event
     */
    function handleMobileDropdown(event) {
        const element = event.target;
        if (element.nextElementSibling.classList.contains('active')) {
            element.nextElementSibling.classList.remove('active');
        } else {
            element.nextElementSibling.classList.add('active');
        }
    }

    const handleMassRenewAction = async () => {
        const period = 1;
    }

    function handleCheckDomainChange(event, domain) {

        const checked = event.target.checked;
        const existsInSelection =  massActionSelection.length > 0 ? massActionSelection.find((item) => domain._id === item._id) : undefined;
        // if checked and not in list -> add
        // if unchecked and in list -> remove
        if (checked) {
            if (existsInSelection === undefined) {
                setMassActionSelection([...massActionSelection, domain])
            }
        } else {
            if (existsInSelection !== undefined) {
                const massActionSelectionFiltered = massActionSelection.filter((item) => domain._id !== item._id);
                setMassActionSelection(massActionSelectionFiltered);
            }
        }
    }

    /**
     * JSX
     */
    return (
        <div className={'app'}>
            {/* MODAL DIALOG WITH SETTINGS */}
            <MyModal closeSettings={closeSettings} showSettings={showSettings} user={user} setUser={setUser} setUsers={setUsers} users={users}/>
            {/* SIDE BAR */}
            <aside className={'side'} >
                {/* LOGO */}
                <figure className={'side__logo'}>
                    <img src={sideLogo} alt={'Pixel Design'}/>
                </figure>
                {/* NAVIGATION */}
                <nav className='side__nav'>
                    <ul>
                        <li><MyButton className={mode === 'domains' ? 'button bigger w-100 blue' : 'button bigger w-100 border-blue'}
                                      onClick={() => handleChangeModeClick('domains')}>Domény</MyButton></li>
                        <li><MyButton className={mode === 'history' ? 'button bigger w-100 blue' : 'button bigger w-100 border-blue'} onClick={() => handleChangeModeClick('history')}>
                            Transakce</MyButton></li>
                        {/*{testMode && <li><MyButton className={'button bigger w-100 green'} onClick={() => wait(2000)}>Time Out</MyButton></li>}*/}
                    </ul>
                </nav>
                {/* CONTROL SETTINGS */}
                <MySettings handleLimit={handleLimit}
                            actuallimit={limit}
                            domainstotal={domains.length}
                            sorting={sorting}
                            handleSorting={handleSortClick}
                            mode={mode}
                            filteredDomains={filteredDomains}
                            filteredHistory={filteredHistory}
                            toggleEditMode ={toggleEditMode}
                            allowEdit={allowEdit}
                            handleMobileDropdown={handleMobileDropdown}
                            massActionSelection={massActionSelection}
                            showMassActionModal={showMassActionModal}
                            allowMassAction={allowMassAction}
                            toggleAllowMassAction={toggleAllowMassAction}
                />

                {/* STATUS, STATS, USER */}
                <StatusBar domains={domains} history={history} logOut={props.logOut} soonExpires credit={credit} openSettings={openSettings} user={user} test={testMode} handleMobileDropdown={handleMobileDropdown}/>
            </aside>

            {/*  MSG BAR ???  */}
            {/*<div>*/}
            {/*    <MsgBar className={getBarClassName()} message={message} />*/}
            {/*</div>*/}

            {/* MAIN */}

            <div className={'app__main'}>

                {/* SEARCH INPUT */}
                <section className={'app__search'}>
                    <Input onChange={handleFilter} value={filter} placeholder={'Zadejte název domény / klienta'} nolabel={true} style={{zIndex: 0}}/>
                </section>

                {/* DOMAINS TABLE */}
                <div className={'app__box'}>
                    <section className={'app__table'}>
                    {filteredDomains && mode === 'domains' &&
                        <table>
                            <tbody>
                            <tr>
                                {allowMassAction && <th>&nbsp;</th>}
                                {allowEdit && <>
                                    <th>Doména</th>
                                    <th>Datum expirace</th>
                                    <th>Klient, Poznámka</th>
                                </>}
                                {!allowEdit && <>
                                    <th>Doména</th>
                                    <th>Klient</th>
                                    <th>Poznámka</th>
                                    <th>Datum expirace</th>
                                    <th className={'text-center'}>Zbývá dnů</th>
                                    <th>Prodloužit o</th>
                                </>}
                            </tr>
                            {/* map domains to table - limit kicks in here */}
                            {filteredDomains.slice(0, limit).map(domain => {
                                let expiryDays = _resolveDaysLeft(domain.expiryDate);
                                return (
                                    <tr key={domain._id}>
                                        {allowMassAction && <td><input type={'checkbox'}
                                                    onChange={(event) => handleCheckDomainChange(event, domain)}/></td>}
                                        <td style={{width: '15%'}}>{domain.name}</td>
                                        {allowEdit && <>
                                            <td style={{width: '10%'}}>{new Date(domain.expiryDate).toLocaleDateString()}</td>
                                            <td style={{width: '75%'}}>
                                                <form onSubmit={(e) => handleEditOwnerSubmit(e, domain)}>
                                                    <div className={'app__table__input'}  style={{justifyContent: 'space-between'}}>
                                                        <Input defaultValue={domain.owner} name='owner' placeholder={'Klient'} />
                                                        <MyButton className='button green' type='submit' name={'myEditButton'}>uložit</MyButton>
                                                    </div>
                                                    <div className={'input-container'} style={{marginTop: '0.5em', marginBottom: '0'}}>
                                                        <Input defaultValue={domain.note} name='note' placeholder={'Poznámka'}/>
                                                    </div>
                                                </form>
                                            </td>
                                        </>}
                                        {!allowEdit && <>
                                            <td>{domain.owner}</td>
                                            <td>
                                                {domain.note.length < 35 && domain.note}
                                                {domain.note.length >= 35 &&
                                                    <div className={'tooltip'}>{domain.note.substring(0, 34)}..
                                                        <span className={'tooltiptext'}>
                                                            <p>{domain.note}</p>
                                                        </span>
                                                    </div>
                                                }
                                            </td>
                                            <td>{new Date(domain.expiryDate).toLocaleDateString()}</td>
                                            <td className={'text-center'}>{expiryDays}</td>
                                            <td className={'app__table__qty'}>
                                                <RenewForm domain={domain} handleRenewClick={handleRenewClick} />
                                            </td>
                                        </>}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>}

                    {/* HISTORY TABLE */}
                    {filteredHistory && mode === 'history' &&
                        <table>
                            <tbody>
                            <tr>
                                <th>Doména</th>
                                <th>Klient</th>
                                <th>Původní expirace</th>
                                <th>Prodlouženo o</th>
                                <th>Cena</th>
                                <th>Prodlouženo</th>
                                <th>Fakturace</th>
                            </tr>
                            {/* map domains to table - limit kicks in here */}
                            {filteredHistory.slice(0, limit).map(domain => {
                                return (
                                    <tr key={domain._id}>
                                        <td >{domain.name}</td>
                                        <td>{domain.owner}</td>
                                        <td>{new Date(domain.previousExpiry).toLocaleDateString()}</td>
                                        <td>{domain.renewYears * 12} měsíců</td>
                                        <td>{Math.round(domain.renewPrice)} Kč</td>
                                        <td>{new Date(domain.renewDate).toLocaleDateString()} ({_getUserName(domain.user)})</td>
                                        {!domain.isBilled && <td>
                                            <MyButton
                                                className='button blue'
                                                onClick={() => {
                                                    handleBillConfirmClick(domain)
                                                }}
                                            >Potvrdit
                                            </MyButton>
                                        </td>}
                                        {domain.isBilled && <td>{new Date(domain.billConfirmedAt).toLocaleDateString()} ({_getUserName(domain.billConfirmedBy)})</td>}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>}
                    </section>
                </div>
            </div>
            {/* MODAL DIALOG WITH MASS ACTION */}
            <ModalMassAction
                showMassAction={showMassAction}
                massActionSelection={massActionSelection}
                handleRenewDomainInMassAction={handleRenewDomainInMassAction}
                setMassActionSelection={setMassActionSelection}
                setAllowMassAction={setAllowMassAction}
                setShowMassAction={setShowMassAction}
                reloadApp={reloadApp}
                />
        </div>
    )

    /**
     * Helper function
     * @param date expiry date
     * @returns {number} days left from expiry date
     * @private
     */
    function _resolveDaysLeft(date) {
        const oneDay = 1000 * 60 * 60 * 24;
        const expiryDate = new Date(date)
        const today = new Date();
        let diff = expiryDate.getTime() - today.getTime();
        return Math.round(diff / oneDay);
    }


    /**
     * Helper function
     * @param array
     * @param column with date string
     * @returns {array} sorted by date
     * @private
     */
    function _sortByDate(array, column) {
        array.sort((a, b) => {
            let da = new Date(a[column]);
            let db = new Date(b[column]);
            return db - da;
        });
        return array;
    }

    /**
     * Helper function
     * @param email user email
     * @returns {string} user whole name
     * @private
     */
    function _getUserName(email) {
        if (!email) {
            return '';
        }
        const currentUser = users.find(user => user.email === email);
        if (typeof currentUser.name === 'string' && typeof currentUser.surname === 'string') {
            return `${currentUser.name.slice(0,1)}. ${currentUser.surname}`;
        }
        return email;
    }
}
