import {BrowserRouter, Switch, Route} from "react-router-dom";
import config from "./config";
import App from "./App";
import Login from "./components/Login";
import {useState} from "react";
import {MyProvider} from "./context/MyContext";

/**
 * First component.
 * Handles Context provider
 * Handles log in and related stuff
 * State: loggedIn
 * @returns {JSX.Element}
 */
export default function MyRouter() {


    const [loggedIn, setLoggedIn] = useState(!!sessionStorage.getItem(config.sessionStorageKey));

    function logOut() {
        sessionStorage.removeItem(config.sessionStorageKey);
        sessionStorage.removeItem('userName');
        setLoggedIn(false);
    }

    function logIn() {
        setLoggedIn(true);
    }

    return (
        <BrowserRouter>
            <MyProvider>
                {loggedIn && <App logOut={logOut} />}
                {!loggedIn && <Login logIn={logIn} />}
            </MyProvider>
        </BrowserRouter>
    )
}