import { createContext, useState } from "react";

const MyContext = createContext();

function MyProvider(props) {
    const [isLoading, setIsLoading] =  useState(false);

    function loadingOn() {
        setIsLoading(true);
    }
    function loadingOff() {
        setIsLoading(false);
    }

    const value = {
        isLoading: isLoading,
        loadingOn: loadingOn,
        loadingOff: loadingOff,
    }

    return (
        <MyContext.Provider value={value}>
            {props.children}
        </MyContext.Provider>
    );
}

export { MyContext, MyProvider };