import Modal from 'react-modal';
import MyButton from "./MyButton";
import {useState} from "react";
import Loader from "./Loader";


/**
 * Modal with Mass Action
 * @param props
 * @returns {JSX.Element}
 */
export default function ModalMassAction(props) {

    // modal error & success messages

    const [loading, setLoading] = useState(false);
    const [isProcessed, setIsProcessed] = useState(false)
    const [errors, setErrors] = useState([])

    // variables from props
    const {showMassAction, massActionSelection, handleRenewDomainInMassAction, setShowMassAction, setMassActionSelection, setAllowMassAction, reloadApp} = props;

    // modal styles
    const customStyles = {
        content: {
            maxWidth: '50%',
            minHeight: '40%',
            maxHeight: '60%',
            left: '25%',
        },
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
        }
    };

    const handleMassRenew = async () => {
        setLoading(true);
        for (const domain of massActionSelection) {
            const error = await handleRenewDomainInMassAction(domain, 1)
            const domainIndex = massActionSelection.indexOf(domain);
            const domainSelectionCopy = [...massActionSelection];
            domainSelectionCopy[domainIndex].error = error;
            setMassActionSelection(() => [...domainSelectionCopy]);
        }
        setLoading(false);
        setIsProcessed(true);
    }

    /**
     * close modal dialog with massAction
     */
    function hideCloseMassActionModal() {
        if (isProcessed) {
            setAllowMassAction(false);
            setMassActionSelection([]);
            setIsProcessed(false);
            reloadApp();
        }
        setShowMassAction(false);
    }


    return <Modal

        isOpen={showMassAction}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={customStyles}
        ariaHideApp={false}
    >
        <div className={'app__box'}>
            {!isProcessed && !loading && <MyButton className={'button green'} onClick={handleMassRenew}>Start</MyButton>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MyButton className={'button blue'} onClick={hideCloseMassActionModal}>Zavřít</MyButton>
            <hr style={{marginTop: '30px', color: "lightgray"}}/>
            <h4 style={{marginTop: '25px', marginBottom: '25px'}}>Vybrané domény k hromadnému prodložení o rok:</h4>
                <table style={{padding: '30px'}}>
                    <tbody>
                    <tr>
                        <th></th>
                        <th>{loading && <Loader size={'25px'} />}</th>
                    </tr>
                    {massActionSelection.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td style={{textAlign:'left', paddingLeft: '25px'}}>{item.name}</td>
                                <td>
                                    {item.error === false && <span style={{color: 'darkgreen'}}>OK</span>}
                                    {typeof item.error === 'string' && <span style={{color: 'darkred'}}>{item.error}</span>}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
        </div>
    </Modal>
}