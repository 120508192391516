import MyButton from "./MyButton";
import {useEffect, useState} from "react";

/**
 * Component which handles rendering domain renew buttons
 * @param props
 * @returns {JSX.Element}
 */
export default function RenewForm(props) {

    // renew period
    const [years, setYears] = useState(1);
    // what to display as button text
    const [buttonString, setButtonString] = useState('rok');

    const {handleRenewClick, domain} = props;

     // change button text on renew period change
    useEffect(() => {
        let number = Number.parseInt(years)
        if (isNaN(number)) {
            number = 0;
        }

        switch (number) {
            case 0:
            case 1:
                setButtonString('rok');
                break;
            case 2:
            case 3:
            case 4:
                setButtonString(`roky`);
                break;
            default:
                setButtonString(`let`);
                break;
        }
    },[years])

    // 10 is max
    function handlePlusClick() {
        if (years < 10) {
            setYears(years => years + 1)
        }
    }

    // 1 is minimum
    function handleMinusClick() {
        if (years > 1) {
            setYears(years => years - 1)
        }
    }

    return <>
            <div className={'app__qty'}>
                <div className={'button down'} onClick={handleMinusClick} style={{zIndex: 0}}>-</div>
                <div className={'button up'} onClick={handlePlusClick} style={{zIndex: 0}}>+</div>
            </div>
            <div className={''}>
                <MyButton
                    onClick={() => handleRenewClick(domain, years)}
                    className='button blue'
                    type={'submit'}
                >{years} {buttonString}
                </MyButton>
            </div>
    </>
}