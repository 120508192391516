/**
 * Svg loading animation
 * stateless
 * @param props
 * @returns {JSX.Element}
 */
export default function Loader(props) {
    let size = "33px";
    if (props.size !== undefined) {
        size = props.size;
    }

    return (
        <svg
            className="spinner"
            width={size}
            height={size}
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                className="path"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
            ></circle>
        </svg>
    );
}