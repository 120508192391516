import clsx from 'clsx';

/**
 * Universal input component
 * stateless
 * @param props
 * @returns {JSX.Element}
 */
export default function Input(props) {

    const {placeholder, label, className, type = 'text', required, nolabel, ...rest} = props
    const classNames = clsx({ input: true }, className);

    if (nolabel) {
        return (
            <div>
                <input
                    className={classNames}
                    type={type}
                    placeholder={placeholder}
                    required={required}
                    {...rest} />
            </div>
        )
    }

    return (
        <label className="label">
            {required && <span className="input-required">*</span>}
            <div>
                <input
                    className={classNames}
                    type={type}
                    placeholder={placeholder}
                    required={required}
                    {...rest} />
            </div>
        </label>
    );
}