import MyButton from "./MyButton";
import {useContext} from "react";
import {MyContext} from "../context/MyContext";
import Loader from "./Loader";

/**
 * Renders status bar
 * stateless
 * @param props
 * @returns {JSX.Element}
 */
export default function StatusBar(props) {

    const {isLoading} = useContext(MyContext);
    const {domains, history, empty, logOut, credit, openSettings, user, handleMobileDropdown} = props;
    const today = new Date();

    // empty status bar (used at login page)
    if (empty) {
        return (
            <div className="App-empty-status-bar-header">
                <div>SPRÁVA DOMÉN</div>
            </div>
        );
    }

    // prepare data to show
    const thisMonthExpiresRenewed = _getCountInThisMonth(history, 'previousExpiry');
    const thisMonthExpiresLeft = _getCountInThisMonth(domains, 'expiryDate');

    return (
        <>
            <div className={'side__box stat-ico'}>
                <header tabIndex={0} onClick={(event => handleMobileDropdown(event))}>Statistiky</header>
                <section>
                    <div className={'side__stats'}>
                        <span>celkem domén:</span>
                        <span className={'text-bigger text-green text-light'}>{domains.length}</span>
                    </div>
                    <div className={'side__stats'}>
                        <span>do 30ti dní expiruje:</span>
                        <span className={'text-bigger text-green text-light'}>{_getCountByDaysLeft(domains,30)}</span>
                    </div>
                    <div className={'side__stats'}>
                        <span>{today.toLocaleString('domains', { month: 'long' })} : prodlouženo/zbývá</span>
                        <span className={'text-bigger text-green text-light'}>{thisMonthExpiresRenewed}/{thisMonthExpiresLeft}</span>
                    </div>
                    <div className={'side__stats'}>
                        <span>aktální kredit</span>
                        <span className={'text-bigger text-green text-light'}>{Math.floor(Number.parseFloat(credit)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} Kč</span>
                    </div>
                </section>
            </div>
            <div className={'side__box user-ico'}>
                <header tabIndex={0} onClick={(event => handleMobileDropdown(event))}>Uživatel</header>
                <section>
                    <p className={'text-bigger text-green text-light'}>{user.name} {user.surname}</p>
                    <p><MyButton onClick={() => openSettings()} className='button w-100 border-white'>Upravit</MyButton></p>
                    <p><MyButton onClick={logOut} className='button w-100 red'>Odhlásit se</MyButton></p>
                </section>
            </div>

            {/*LOADER ??? */}

            {/*<div className="App-status-bar-header">*/}
            {/*    {isLoading && <Loader />}*/}
            {/*    {!isLoading && !test && <span>PD</span>}*/}
            {/*    {!isLoading && test && <span style={{color:"red"}}>TEST</span>}*/}
            {/*</div>*/}
        </>
    );


    /**
     * Helper function
     * @param domains
     * @param days
     * @returns {number}
     * @private
     */
    function _getCountByDaysLeft(domains, days) {
        let count = 0;
        domains.map(item => {
            if (_resolveDaysLeft(item.expiryDate) <= days) {
                count++
            }
        })
        return count;
    }

    /**
     * Helper function
     * @param date {string}
     * @returns {number} day difference from today
     * @private
     */
    function _resolveDaysLeft(date) {
        const oneDay = 1000 * 60 * 60 * 24;
        const expiryDate = new Date(date)
        const today = new Date();
        let diff = expiryDate.getTime() - today.getTime();
        return Math.round(diff/oneDay);
    }

    /**
     * Helper function
     * @param items {array}
     * @param column
     * @returns {*} number if items in array who have the current moth in column string
     * @private
     */
    function _getCountInThisMonth(items, column) {
        const thisMonthString = new Date().toISOString().slice(0,7);
        const filtered = items.filter(item => {
            if(item[column] !== undefined) {
                return item[column].includes(thisMonthString);
            }
            return false;
        })
        return filtered.length;
    }
}