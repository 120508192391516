// import Modal from 'react-bootstrap/Modal';
import Modal from 'react-modal';
import {useState} from "react";
import config from "../config";
import MyButton from "./MyButton.js";
import Loader from "./Loader";
import Input from "./Input";
import logo from "../img/logo.svg";

// modal styles
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '25%'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    }
};

/**
 * Login component
 * @param props
 * @returns {JSX.Element}
 */
export default function Login(props) {

    const [isLoading, setIsLoading] = useState(false);

    // modal
    const [isOpen, setIsOpen] = useState(false);

    // for conditional rendering of recover password modal dialog
    const [recoverPassword, setRecoverPassword] = useState(false);
    const [passwordRecovered, setPasswordRecovered] = useState(false);

    // login form errors
    const [error, setError] = useState(null);

    // error or success messages for password recover modal
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');

    // login function
    const {logIn} = props;

    /**
     * Login request
     * @param email
     * @param password
     * @returns {Promise<void>}
     */
    async function login (email, password) {
        setIsLoading(true);
        // fetch props
        const loginFetchProps = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email, password: password})
        }
        try {
            // login to Node.js server
            const fetchedData = await fetch(`/api/user/login`, loginFetchProps);
            const parsedData = await fetchedData.json();

            // logged in to server
            if (parsedData.accessToken) {
                const syncFetchProps = {
                    headers: {
                        authorization: `Bearer ${parsedData.accessToken}`
                    }
                }

                // sync DB with remote server
                const syncDB = await fetch(`/api/tele3/sync`, syncFetchProps);
                const syncDbParsed = await syncDB.json();

                // synced successfully
                if (syncDbParsed.success) {
                    sessionStorage.setItem(config.sessionStorageKey, parsedData.accessToken);
                    sessionStorage.setItem('userName', email);
                    sessionStorage.setItem('test', parsedData.test)
                    // LOGIN
                    logIn();
                } else {
                    setError(syncDbParsed.errorCode)
                }
            } else {
                setError(parsedData.errorCode)
            }
        } catch (e) {
            console.log(e);
            setError(e.toString());
        }
        setIsLoading(false);
    }

    /**
     * Submits login
     * @param e login form submit event
     * @returns {Promise<void>}
     */
    async function handleSubmit(e) {
        e.preventDefault();
        if (!e.target.email.value) {
            setError("Zadejte email");
        } else if (!e.target.password.value) {
            setError("Zadejte heslo");
        } else {
            await login(e.target.email.value, e.target.password.value);
        }
    }

    /**
     * show password recovery modal
     */
    function showModal() {
        setIsOpen(true);
        setRecoverPassword(true);
        setPasswordRecovered(false);
        setFormError('');
        setFormSuccess('');
    }

    /**
     * hide password recovery modal
     */
    function hideModal() {
        setIsOpen(false);
        setRecoverPassword(false);
        setPasswordRecovered(false);
        setFormError('');
        setFormSuccess('');
    }

    /**
     * Send request for password recovery
     * @param e recover password submit event
     */
    function handleRecoverPassSubmit(e) {
        e.preventDefault()
        setFormError('');
        const email = e.target.email.value;
        const fetchProps = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email})
        }
        // send request
        fetch('/api/user/recover', fetchProps)
            .then(response => response.json())
            .then(data => {
                if (data.errorCode) {
                    setFormSuccess('');
                    return Promise.reject('došlo k chybě na serveru: ' + data.errorCode);
                } else {
                    setFormSuccess('Nové heslo zasláno na ' + email);
                    setPasswordRecovered(true);
                }
            })
            .catch(err => setFormError(err))
    }

    // JSX
    return (
        <>
            {/* PASSWORD RECOVERY MODAL */}
            {/*{recoverPassword &&*/}
            {/*    <Modal*/}
            {/*        show={showModal}*/}
            {/*        onHide={hideModal}*/}
            {/*        animation={true}*/}
            {/*        centered={true}*/}
            {/*    >*/}
            {/*        <div className={'app__box pa-2'}>*/}
            {/*        <form onSubmit={handleRecoverPassSubmit} style={{marginLeft:'1vh', marginRight:'1vh'}}>*/}
            {/*            <Modal.Header closeButton>*/}
            {/*                <Modal.Title>Obnovit heslo</Modal.Title>*/}
            {/*            </Modal.Header>*/}
            {/*            <Modal.Body>*/}
            {/*                {!passwordRecovered && <div className={'input-container'}>*/}
            {/*                    <label>Email:</label>*/}
            {/*                    <Input name={'email'} type={'email'} nolabel={true}/>*/}
            {/*                </div>}*/}
            {/*                {formError && <div className={'My-form-error'}>{formError}</div>}*/}
            {/*                {formSuccess && <div className={'My-form-success'}>{formSuccess}</div>}*/}
            {/*            </Modal.Body>*/}
            {/*            <Modal.Footer >*/}
            {/*                {!passwordRecovered && <MyButton className={'button green'} type={'submit'}>Obnovit</MyButton>}*/}
            {/*                {passwordRecovered && <MyButton onClick={hideModal} className={'button blue'} type={'button'}>Ok</MyButton>}*/}
            {/*            </Modal.Footer>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*    </Modal>*/}
            {/*}      */}
            {recoverPassword &&
                <Modal
                    isOpen={isOpen}
                    onRequestClose={hideModal}
                    style={customStyles}
                >
                    <div className={'app__box pa-2'}>
                    <form onSubmit={handleRecoverPassSubmit} style={{marginLeft:'1vh', marginRight:'1vh'}}>
                            <h3>Obnovit heslo</h3>
                        <div>
                            {!passwordRecovered && <div className={'input-container'}>
                                <label>Email:</label>
                                <Input name={'email'} type={'email'} nolabel={true}/>
                            </div>}
                            {formError && <div className={'My-form-error'}>{formError}</div>}
                            {formSuccess && <div className={'My-form-success'}>{formSuccess}</div>}
                        </div>
                        <div >
                            {!passwordRecovered && <MyButton className={'button green'} type={'submit'}>Obnovit</MyButton>}
                            {passwordRecovered && <MyButton onClick={hideModal} className={'button blue'} type={'button'}>Ok</MyButton>}
                        </div>
                    </form>
                </div>
                </Modal>
            }
            {/* PAGE HEADER - graphics only  */}
            {/*<Header/>*/}
            {/* STATUS BAR - emty */}
            {/*<StatusBar empty={true}/>*/}
            {/* LOGIN FORM */}
            <div className="login">
                <div className="login__logo"><img src={logo} alt="Pixel Design s.r.o." /></div>
                <div className='login__form'>
                    <form onSubmit={handleSubmit}>
                        <div className={'login__container'}>
                            <label htmlFor="login-email">Uživatelské jméno</label>
                            <input type="text" name="email" id="login-email" />
                        </div>
                        <div>
                            <div className="login__container">
                                <label htmlFor="login-pass">Heslo
                                    {error && <span style={{color:"white", textShadow: "1px 1px 1px red"}}>&nbsp;- {error}</span>}
                                </label>
                                <input type="password" name="password" id="login-pass" />
                            </div>
                        </div>
                        {!isLoading && <MyButton type='submit' className={'button green'}>Přihlásit se</MyButton>}
                        {isLoading && <Loader size={'50px'}/>}
                    </form>
                </div>
                <div className={'login__forgotten'}><MyButton type='button' onClick={showModal}>zapomenuté heslo</MyButton></div>
                <div className={"login__footer"}>
                    <p>© 2022 Provozovatelem systému je společnost Pixel Design s. r. o.<br />se sídlem Prokopská 291, 565
                        01 Choceň, IČ: 066 68 712<br /><a href="https://www.pixeldesign.cz">www.pixeldesign.cz</a></p>
                </div>
            </div>
        </>
    )
}
