import {useState, useEffect} from "react";
import config from "./config";
import MyApp from "./components/MyApp";
import {useContext} from "react";
import {MyContext} from "./context/MyContext";
import swal from "sweetalert";

/**
 * Kind of transition component. After successful login, get data from server and pass them to the MyApp component, which is the main application component
 * State: {domains, history, users, credit} we put data to state, but we do not pass functions that alter it to MyApp
 * State: dataIsLoaded - triggers loading MyApp
 * @param props - logout() function to be passed further
 * @returns {JSX.Element}
 */
export default function App(props) {

    const [domains, setDomains] = useState([]);
    const [history, setHistory] = useState([]);
    const [users, setUsers] = useState([]);
    const [credit, setCredit] = useState(0)
    const [dataIsLoaded, setDataIsLoaded] = useState(false)
    const isLoading = useContext(MyContext);

    useEffect(() => {

        isLoading.loadingOn();
        const fetchProps = {};
        const accessToken = sessionStorage.getItem(config.sessionStorageKey);
        fetchProps.headers = {
            authorization: `Bearer ${accessToken}`
        }

        // get domains from DB
        const getDbDomains = async () => {
            try {
                const dbDomainsData = await fetch('/api', fetchProps);
                const dbDomainsDataParsed = await dbDomainsData.json();
                if (dbDomainsData.status >= 200 && dbDomainsData.status < 300) {
                    return dbDomainsDataParsed;
                } else {
                    throw new Error(dbDomainsDataParsed.errorCode ?? 'Chyba komunikace se serverem');
                }
            } catch (e) {
                return {errorCode: e}
            }
        }

        // get history from DB
        const getDbHistory = async () => {
            try {
                const dbHistoryData = await fetch('/api/history', fetchProps);
                const dbHistoryDataParsed = await dbHistoryData.json();
                if (dbHistoryData.status >= 200 && dbHistoryData.status < 300) {
                    return dbHistoryDataParsed;
                } else {
                    throw new Error(dbHistoryDataParsed.errorCode ?? 'Chyba komunikace se serverem');
                }
            } catch (e) {
                return {errorCode: e}
            }

        }

        // get users from DB
        const getDbUsers = async () => {
            try {
                const dbUserData = await fetch('/api/user', fetchProps);
                const dbUserDataParsed = await dbUserData.json();
                if (dbUserData.status >= 200 && dbUserData.status < 300) {
                    return dbUserDataParsed;
                } else {
                    throw new Error(dbUserDataParsed.errorCode ?? 'Chyba komunikace se serverem');
                }
            } catch (e) {
                return {errorCode: e}
            }

        }

        // get api stats
        const getApiStats = async () => {
            try {
                const apiStatsRaw = await fetch('/api/tele3/stats', fetchProps);
                const apiStatsParsed = await apiStatsRaw.json();
                if (apiStatsRaw.status >= 200 && apiStatsRaw.status < 300) {
                    return apiStatsParsed;
                } else {
                    throw new Error(apiStatsParsed.errorCode ?? 'Chyba komunikace se serverem');
                }
            } catch (e) {
                return {errorCode: e}
            }

        }

        // calls all the get functions and store the data in state
        const setStates = async () => {
            const dbDomains = await getDbDomains();
            const dbHistory = await getDbHistory();
            const dbUsers = await getDbUsers();
            const apiStats = await getApiStats();

            // collect errors so that we show them as one
            let error = '';
            if (dbDomains.errorCode) {
                error += dbDomains.errorCode + ' ';
            }
            if (dbHistory.errorCode) {
                error += dbHistory.errorCode + ' ';
            }
            if (dbUsers.errorCode) {
                error += dbUsers.errorCode + ' ';
            }
            if (apiStats.errorCode) {
                error += apiStats.errorCode + ' ';
            }

            // got an error - show it and return back to login
            if (error) {
                await swal({
                    title: 'FATAL ERROR, zkuste to prosím později..',
                    text: `${error}`,
                    icon: 'error',
                    button: 'Co nadělám',
                    dangerMode: true
                });
                isLoading.loadingOff();
                props.logOut();

            // no error - save data and tell the app data is loaded
            } else {
                // set data
                setDomains(dbDomains);
                setHistory(dbHistory);
                setUsers(_remapUsers(dbUsers.users))
                setCredit(apiStats.credit);

                isLoading.loadingOff();
                setDataIsLoaded(true);
            }
        }

        // this basically launches all the previous code
        setStates();

    },[]);

    // when th data is loaded, pass them to the MyApp component
    return (
        <>
            <div className="App">
                {dataIsLoaded &&  <MyApp
                    domains={_sortDomainsByExpiryDate(domains)}
                    history={_sortHistoryByDate(history)}
                    users={users}
                    user={_getCurrentUser(users)}
                    logOut={props.logOut}
                    credit={credit}
                />}
            </div>
        </>
    )

    /**
     * Helper function
     * @param domainsItems
     * @returns Array of domain objects sorted by expiry date
     * @private
     */
    function _sortDomainsByExpiryDate(domainsItems) {
        return domainsItems.sort((a, b) => {
            let da = new Date(a.expiryDate);
            let db = new Date(b.expiryDate);
            return da - db;
        });
    }

    /**
     * Helper function
     * @param historyItems
     * @returns Array of history objects sorted by date
     * @private
     */
    function _sortHistoryByDate(historyItems) {
        return historyItems.sort((a, b) => {
            let da = new Date(a.renewDate);
            let db = new Date(b.renewDate);
            return da - db;
        });
    }

    /**
     * Helper function
     * @param users
     * @returns Array of user objects with just email and names
     * @private
     */
    function _remapUsers(users) {
        return users.map(user => {
            return {
                email: user.email,
                name: user.name,
                surname: user.surname
            }
        })
    }

    /**
     * Helper function
     * Get current user data based on user email set to sessionStorage on login
     * @param users
     * @returns user object
     * @private
     */
    function _getCurrentUser(users) {
        const userEmail = sessionStorage.getItem('userName');
        return users.find(user => {
            return user.email === userEmail
        })
    }
}