// import Modal from 'react-bootstrap/Modal';
import Modal from 'react-modal';
import MyButton from "./MyButton";
import Input from "./Input";
import {useEffect, useState, useContext} from "react";
import config from "../config";
import {MyContext} from "../context/MyContext";

// modal styles
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '40%'

    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    }
};


/**
 * Modal with User/App settings
 * @param props
 * @returns {JSX.Element}
 */
export default function MyModal(props) {

    // modal error & success messages
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');

    const {loadingOn, loadingOff} = useContext(MyContext);

    // variables from props
    const {closeSettings, showSettings, user, setUser, users, setUsers} = props;

    // make fields readonly on successful change request
    const disabledFields = formSuccess === '' ? false : 'readonly';

    // reset messages on
    useEffect(() => {
        setFormError('');
        setFormSuccess('');
    },[showSettings])

    /**
     * send user settings change request
     * @param event form submit
     */
    function handleSettingsSubmit(event) {
        event.preventDefault();
        let myError = '';

        const userItem = {
            email: event.target.email.value.trim(),
            name: event.target.name.value.trim(),
            surname: event.target.surname.value.trim(),
        }

        const userItemWithPasswords = {
            ...userItem,
            originalPass: event.target.originalPass.value.trim(),
            newPass: event.target.newPass.value.trim(),
            newPassAgain: event.target.newPassAgain.value.trim()
        }

        // FE validation
        if (userItem.name === '') {
            myError += ' jméno je povinné,';
        }
        if (userItem.surname === '') {
            myError += ' příjmení je povinné,';
        }

        if (userItemWithPasswords.newPass.trim().length > 0) {
            if (userItemWithPasswords.originalPass === '') {
                myError += ' původní heslo je povinné,';
            }
            if (userItemWithPasswords.newPass.trim().length < 6) {
                myError += ' nové heslo musí mít alespoň 6 znaků,';
            }
            if (userItemWithPasswords.newPass.trim() !== userItemWithPasswords.newPassAgain.trim()) {
                myError += ' nová hesla se neshodují,';
            }
        }
        // if errors, parse them into one msg and stop function
        if (myError.trim().length > 0) {
            let parsedString = myError.trim();
            parsedString = parsedString.charAt(0).toUpperCase() + parsedString.slice(1);
            parsedString = parsedString.slice(0,-1);
            setFormError(parsedString);
            return;
        }

        // valid
        loadingOn();
        const accessToken = sessionStorage.getItem(config.sessionStorageKey);
        const fetchBody =  userItemWithPasswords.newPass.trim().length > 0 ? userItemWithPasswords : userItem;
        const fetchProps = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({updatedUser: fetchBody})
        }
        // send request
        fetch('api/user/update', fetchProps)
            .then(response => response.json())
            .then(data => {
                if (data.errorCode) {
                    setFormSuccess('');
                    return Promise.reject('došlo k chybě na serveru: ' + data.errorCode);
                } else {
                    setFormError('');
                    if (data.passwordChanged) {
                        setFormSuccess('Osobní údaje uloženy, heslo změněno');
                    } else {
                        setFormSuccess('Osobní údaje uloženy');
                    }
                    const newUsers = users.filter(user => user.email !== userItem.email);
                    setUsers([...newUsers, userItem])
                    setUser(userItem);
                }
            })
            .catch(err => setFormError(err))
            .finally(() => {
                loadingOff();
            })
    }

    return <>
        {/* MODAL */}
        <Modal
            isOpen={showSettings}
            onRequestClose={closeSettings}
            style={customStyles}
        >
            <form onSubmit={handleSettingsSubmit} style={{marginLeft:'2vh', marginRight:'2vh'}} autoComplete={'off'}>

                <div className={'app__box pa-1'}>
                    <h3>Nastavení</h3>
                    <fieldset>
                        <div className={'input-container'}>
                            <label style={{textAlign: 'left'}}>Email:</label>
                            <Input name={'email'} defaultValue={user.email} readOnly={'readonly'} nolabel={true}/>
                        </div>
                        <div className={'input-container'}>
                            <label style={{textAlign: 'left'}}>Jméno:</label>
                           <Input name={'name'} defaultValue={user.name}  nolabel={true} readOnly={disabledFields}/>
                        </div>
                        <div className={'input-container'}>
                            <label style={{textAlign: 'left'}}>Příjmení:</label>
                            <Input name={'surname'} defaultValue={user.surname} nolabel={true} readOnly={disabledFields}/>
                        </div>
                        <hr />
                        <div className={'My-form-tooltip'}>nevyplňujte pokud nechcete měnit heslo</div>
                        <div className={'input-container'}>
                            <label style={{textAlign: 'left'}}>Nové heslo:</label>
                            <Input name={'newPass'} className={'makeTextHidden'} nolabel={true} readOnly={disabledFields} />
                        </div>
                        <div className={'input-container'}>
                            <label style={{textAlign: 'left'}}>Nové heslo znovu:</label>
                            <Input name={'newPassAgain'} className={'makeTextHidden'} nolabel={true} readOnly={disabledFields} />
                        </div>
                        <div className={'input-container'}>
                            <label style={{textAlign: 'left'}}>Původní heslo:</label>
                            <Input name={'originalPass'} className={'makeTextHidden'} nolabel={true} readOnly={disabledFields} />
                        </div>
                    </fieldset>
                    {formError && <div className={'My-form-error'}>{formError}</div>}
                    {formSuccess && <div className={'My-form-success'}>{formSuccess}</div>}

                    {!formSuccess && <MyButton className={'button green'} type={'submit'}>Uložit změny</MyButton>}
                    {formSuccess && <MyButton onClick={closeSettings} className={'button blue'} type={'button'}>OK</MyButton>}
                </div>
            </form>
        </Modal>
        {/*<Modal*/}
        {/*    show={showSettings}*/}
        {/*    onHide={closeSettings}*/}
        {/*    animation={true}*/}
        {/*    dialogClassName={'My-Modal-main'}*/}
        {/*>*/}
        {/*    <form onSubmit={handleSettingsSubmit} style={{marginLeft:'2vh', marginRight:'2vh'}} autoComplete={'off'}>*/}
        {/*        <Modal.Header closeButton>*/}
        {/*            <Modal.Title>Nastavení</Modal.Title>*/}
        {/*        </Modal.Header>*/}
        {/*        <Modal.Body>*/}
        {/*            <div className={'settings-fields-container'}>*/}
        {/*                <div className={'settings-fields-line'}>*/}
        {/*                    <div>Email:</div>*/}
        {/*                    <div><Input name={'email'} defaultValue={user.email} className={'form-group form-control My-settings-input'} readOnly={'readonly'} nolabel={true}/></div>*/}
        {/*                </div>*/}
        {/*                <div className={'settings-fields-line'}>*/}
        {/*                    <div>Jméno:</div>*/}
        {/*                    <div><Input name={'name'} defaultValue={user.name} className={'form-group form-control My-settings-input'} nolabel={true} readOnly={disabledFields}/></div>*/}
        {/*                </div>*/}
        {/*                <div className={'settings-fields-line'}>*/}
        {/*                    <div>Příjmení:</div>*/}
        {/*                    <div><Input name={'surname'} defaultValue={user.surname} className={'form-group form-control My-settings-input'} nolabel={true} readOnly={disabledFields}/></div>*/}
        {/*                </div>*/}
        {/*                <hr />*/}
        {/*                <div className={'My-form-tooltip'}>nevyplňujte pokud nechcete měnit heslo</div>*/}
        {/*                <div className={'settings-fields-line'}>*/}
        {/*                    <div>Nové heslo:</div>*/}
        {/*                    <div><Input name={'newPass'} className={'makeTextHidden form-group form-control My-settings-input'} nolabel={true} readOnly={disabledFields} /></div>*/}
        {/*                </div>*/}
        {/*                <div className={'settings-fields-line'}>*/}
        {/*                    <div>Nové heslo znovu:</div>*/}
        {/*                    <div><Input name={'newPassAgain'} className={'makeTextHidden form-group form-control My-settings-input'} nolabel={true} readOnly={disabledFields} /></div>*/}
        {/*                </div>*/}
        {/*                <div className={'settings-fields-line'}>*/}
        {/*                    <div>Původní heslo:</div>*/}
        {/*                    <div><Input name={'originalPass'} className={'makeTextHidden form-group form-control My-settings-input'} nolabel={true} readOnly={disabledFields} /></div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            {formError && <div className={'My-form-error'}>{formError}</div>}*/}
        {/*            {formSuccess && <div className={'My-form-success'}>{formSuccess}</div>}*/}
        {/*        </Modal.Body>*/}
        {/*        <Modal.Footer >*/}
        {/*            {!formSuccess && <MyButton className={'btn btn-success'} type={'submit'}>Uložit změny</MyButton>}*/}
        {/*            {formSuccess && <MyButton onClick={closeSettings} className={'btn btn-primary'} type={'button'}>OK</MyButton>}*/}
        {/*        </Modal.Footer>*/}
        {/*    </form>*/}
        {/*</Modal>*/}
    </>
}